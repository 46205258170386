import React from "react";

import { Button, Img, Text } from "components";

const Slide169TwoPage = () => {
  return (
    <>
      <div className="bg-white-A700 flex flex-col font-archivonarrow items-center justify-end mx-auto w-full">
        <div className="flex flex-col items-center justify-end w-full">
          <div className=" md:px-5 relative w-full">
            <div className=" m-auto w-full h-[100vh]">
              <div className="absolute inset-[0] justify-center m-auto w-full">
                {/* <Img
                  className="absolute h-[1079px] inset-x-[0] mx-auto object-cover top-[0] w-full"
                  src="images/img_rectangle10_1079x1919.png"
                  alt="rectangleTen"
                /> */}
                <div className="absolute inset-y-[0] my-auto  right-[0] md:w-full">
                  <Img
                    className="h-[100%] mx-auto object-cover w-full"
                    src="images/img_shutterstock681073264.png"
                    alt="shutterstock681"
                  />
                  <div className="absolute bottom-[45%] flex flex-col items-center justify-start left-[17%]">
                    {/* <Text
                      className="leading-[24.00px] text-base text-black-900_66 w-full"
                      size="txtArchivoNarrowRegular16"
                    >
                      100% INS tokens will be Airdropped to Community.
                    </Text> */}
                  </div>
                </div>
              </div>
              {/* <Sidebar className="!sticky !w-[374px] flex h-screen md:hidden justify-start left-[0] overflow-auto top-[0]">
                <Img
                  className="h-[1080px] md:h-auto object-cover w-full"
                  src="images/img_shutterstock662753116.png"
                  alt="shutterstock662"
                />
                <Img
                  className="h-[431px] md:h-auto mb-[405px] ml-[180px] mr-[186px] object-cover"
                  src="images/img_frame9.png"
                  alt="frameNine"
                />
              </Sidebar> */}
            </div>
            <div className="absolute bg-black-900_66 flex flex-col font-inter inset-x-[0] items-center justify-start mx-auto p-8 sm:px-5 w-full">
              <div className="flex flex-col items-center justify-start w-[95%] md:w-full">
                <div className="flex md:flex-col flex-row md:gap-5 items-start justify-between w-full">
                  <div>
                    <Text
                      className="md:mt-0 mt-[3px] text-base text-white-A700"
                      size="txtInterRegular16"
                    >
                      © 2077 Untitled UI. All rights reserved.
                    </Text>
                  </div>
                  <div className="flex">
                    <Button className="flex h-6 items-center justify-center ml-4 md:ml-[0] w-6">
                      <Img
                        className="h-4 w-4"
                        src="images/img_twitter687e3bb6svg.svg"
                        alt="twitter687e3bbSix"
                      />
                    </Button>
                    <Button className="flex h-6 items-center justify-center ml-4 md:ml-[0] w-6">
                      <Img src="images/img_user.svg" alt="user" />
                    </Button>
                    <Button className="flex h-6 items-center justify-center ml-4 md:ml-[0] w-6">
                      <Img src="images/img_save.svg" alt="save" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bg-black-900_26 flex flex-row inset-x-[0] items-center justify-start mx-auto p-[23px] sm:px-5 top-[0] w-full">
              <div className="flex md:flex-col flex-row md:gap-5 items-center justify-around w-full gap-10">
                <div className="flex">
                  <Img
                    className="h-[34px]"
                    src="images/img_thumbsup.svg"
                    alt="thumbsup"
                  />
                  <Text
                    className="ml-4 md:ml-[0] text-2xl md:text-[22px] text-black-900_cc sm:text-xl"
                    size="txtArchivoBlackRegular24"
                  >
                    INS NETWORK
                  </Text>
                </div>
                <div className="flex md:flex-col flex-row font-cinzel md:gap-5 items-center justify-center md:ml-[0] w-[44%] md:w-full">
                  <div className="flex flex-row gap-2 items-center justify-start w-[9%] md:w-full">
                    <div className="bg-black-900 h-2 my-1.5 w-2"></div>
                    <Text
                      className="text-base text-black-900"
                      size="txtCinzelRomanBold16"
                    >
                      Stake
                    </Text>
                  </div>
                  <Text
                    className="md:ml-[0] ml-[30px] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    Campaigns
                  </Text>
                  <Text
                    className="ml-8 md:ml-[0] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    Ecosystems
                  </Text>
                  <Text
                    className="ml-8 md:ml-[0] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    Bridge
                  </Text>
                  <Text
                    className="ml-8 md:ml-[0] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    Governance
                  </Text>
                  <Text
                    className="md:ml-[0] ml-[31px] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    Airdrop
                  </Text>
                  <Text
                    className="ml-8 md:ml-[0] text-base text-black-900_cc"
                    size="txtCinzelRomanRegular16Black900cc"
                  >
                    More
                  </Text>
                </div>
                {/* <Img
                  className="h-6 md:ml-[0] ml-[196px] w-6"
                  src="images/img_globe02.svg"
                  alt="globeTwo"
                /> */}
                <Button
                  className="!text-black-900 bg-cover bg-no-repeat cursor-pointer font-cinzel h-8 min-w-[191px] ml-6 md:ml-[0] text-base text-center"
                  style={{ backgroundImage: "url('images/img_connect.png')" }}
                  color="white_A700"
                >
                  Connect Wallet
                </Button>
              </div>
            </div>
            <div className="absolute flex flex-col font-cinzel items-start justify-start left-[11%] top-[17%] w-[53%]">
              <div className="h-10 md:h-[33px] py-0.5 relative w-[42%] sm:w-full">
                <Img
                  className="absolute h-8 inset-[0] justify-center m-auto object-cover rounded-[16px] w-full"
                  src="images/img_rectangle9_32x423.png"
                  alt="rectangleNine"
                />
                <Text
                  className="absolute left-[22%] text-2xl md:text-[22px] text-white-A700 sm:text-xl top-[5%]"
                  size="txtCinzelRomanBold24"
                >
                  Claim Your ins
                </Text>
              </div>
              <div className="flex flex-col font-archivonarrow gap-[39px] items-start justify-start ml-1 md:ml-[0] mt-[18px] w-full">
                <div className="md:h-[248px] h-[264px] sm:h-[719px] ml-0.5 md:ml-[0] relative w-[82%] md:w-full">
                  <div className="absolute flex flex-col items-center justify-start left-[0] top-[0] w-[63%]">
                    <div className="flex flex-col gap-[7px] justify-start w-full">
                      <div className="flex sm:flex-col flex-row sm:gap-10 items-center justify-between ml-1.5 md:ml-[0] w-[99%] md:w-full">
                        <Img
                          className="h-[120px] md:h-auto object-cover"
                          src="images/img_vector.png"
                          alt="vector"
                        />
                        <Img
                          className="h-[115px] md:h-auto object-cover w-[114px] sm:w-full"
                          src="images/img_vector_115x114.png"
                          alt="vector_One"
                        />
                        <Img
                          className="h-[116px] md:h-auto object-cover"
                          src="images/img_vector_116x122.png"
                          alt="vector_Two"
                        />
                        <Img
                          className="h-[121px] md:h-auto object-cover w-[121px]"
                          src="images/img_vector_121x121.png"
                          alt="vector_Three"
                        />
                      </div>
                      <div className="flex flex-row gap-[15px] items-center justify-start w-[49%] md:w-full">
                        <Img
                          className="h-[119px] md:h-auto object-cover w-[48%]"
                          src="images/img_vector_119x116.png"
                          alt="vector_Four"
                        />
                        <Img
                          className="h-[120px] md:h-auto object-cover w-[47%]"
                          src="images/img_vector_120x115.png"
                          alt="vector_Five"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-[24%] flex sm:flex-col flex-row sm:gap-5 items-start justify-evenly right-[0] w-[65%]">
                    <Img
                      className="h-[43px] md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_43x40.png"
                      alt="vector_Six"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_44x41.png"
                      alt="vector_Seven"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_44x40.png"
                      alt="vector_Eight"
                    />
                    <Img
                      className="h-[42px] md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_42x40.png"
                      alt="vector_Nine"
                    />
                    <Img
                      className="h-[35px] md:h-auto sm:mt-0 mt-[3px] object-cover w-[8%] sm:w-full"
                      src="images/img_vector_35x40.png"
                      alt="vector_Ten"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_1.png"
                      alt="vector_Eleven"
                    />
                    <Img
                      className="h-[43px] md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_2.png"
                      alt="vector_Twelve"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[7%] sm:w-full"
                      src="images/img_vector_44x35.png"
                      alt="vector_Thirteen"
                    />
                    <Img
                      className="h-[43px] md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_3.png"
                      alt="vector_Fourteen"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[7%] sm:w-full"
                      src="images/img_vector_44x34.png"
                      alt="vector_Fifteen"
                    />
                    <Img
                      className="h-11 md:h-auto object-cover w-[8%] sm:w-full"
                      src="images/img_vector_44x39.png"
                      alt="vector_Sixteen"
                    />
                    <Img
                      className="h-2 md:h-auto sm:mt-0 mt-8 object-cover w-2"
                      src="images/img_vector_8x8.png"
                      alt="vector_Seventeen"
                    />
                  </div>
                  <div className="absolute bottom-[0] flex sm:flex-col flex-row sm:gap-5 items-start justify-center right-[4%] w-[61%]">
                    <Img
                      className="h-[45px] md:h-auto object-cover w-[9%] sm:w-full"
                      src="images/img_vector_45x43.png"
                      alt="vector_Eighteen"
                    />
                    <Img
                      className="h-10 md:h-auto sm:ml-[0] ml-[9px] sm:mt-0 mt-0.5 object-cover w-[7%] sm:w-full"
                      src="images/img_vector_40x32.png"
                      alt="vector_Nineteen"
                    />
                    <Img
                      className="h-[43px] md:h-auto ml-2.5 sm:ml-[0] object-cover w-[43px]"
                      src="images/img_vector_43x43.png"
                      alt="vector_Twenty"
                    />
                    <Img
                      className="h-10 md:h-auto sm:ml-[0] ml-[5px] sm:mt-0 mt-[3px] object-cover w-[41px] sm:w-full"
                      src="images/img_vector_40x41.png"
                      alt="vector_TwentyOne"
                    />
                    <Img
                      className="h-[43px] md:h-auto sm:ml-[0] ml-[3px] object-cover w-[43px]"
                      src="images/img_vector_4.png"
                      alt="vector_TwentyTwo"
                    />
                    <Img
                      className="h-11 md:h-auto sm:ml-[0] ml-[3px] object-cover w-[9%] sm:w-full"
                      src="images/img_vector_44x42.png"
                      alt="vector_TwentyThree"
                    />
                    <Img
                      className="h-[41px] md:h-auto ml-3.5 sm:ml-[0] sm:mt-0 mt-0.5 object-cover w-[7%] sm:w-full"
                      src="images/img_vector_41x33.png"
                      alt="vector_TwentyFour"
                    />
                    <Img
                      className="h-[38px] md:h-auto sm:ml-[0] ml-[5px] sm:mt-0 mt-1 object-cover w-[39px] sm:w-full"
                      src="images/img_vector_38x39.png"
                      alt="vector_TwentyFive"
                    />
                    <Img
                      className="h-[35px] md:h-auto ml-2.5 sm:ml-[0] sm:mt-0 mt-1.5 object-cover w-[9%] sm:w-full"
                      src="images/img_vector_5.png"
                      alt="vector_TwentySix"
                    />
                    <Img
                      className="h-10 md:h-auto sm:ml-[0] ml-[7px] sm:mt-0 mt-[3px] object-cover w-[8%] sm:w-full"
                      src="images/img_vector_40x37.png"
                      alt="vector_TwentySeven"
                    />
                    <Img
                      className="h-[15px] md:h-auto sm:ml-[0] ml-[7px] sm:mt-0 mt-7 object-cover w-[15px]"
                      src="images/img_vector_15x15.png"
                      alt="vector_TwentyEight"
                    />
                  </div>
                </div>
                <Text
                  className="leading-[24.00px] text-2xl md:text-[22px] text-black-900_cc sm:text-xl w-[82%] sm:w-full"
                  size="txtArchivoNarrowRomanRegular24"
                >
                  The first high-performance modular L2 built specifically for
                  inscription and liberating mobility——Based on Arb orbit L2
                  development
                </Text>
              </div>
              <div className="font-archivoblack h-20 md:h-[157px] ml-1 md:ml-[0] mt-[77px] relative w-[32%]">
                <Img
                  className="h-20 m-auto object-cover rounded w-full"
                  src="images/img_rectangle2.png"
                  alt="rectangleTwo"
                />
                <div className="absolute flex flex-row gap-2 h-max inset-y-[0] items-center justify-center left-[13%] my-auto w-[69%]">
                  <Text
                    className="text-2xl md:text-[22px] text-center text-white-A700 sm:text-xl"
                    size="txtArchivoBlackRegular24WhiteA700"
                  >
                    GET AIRDROP.
                  </Text>
                  <Img
                    className="h-6 w-6"
                    src="images/img_arrowleft.svg"
                    alt="arrowleft"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </>
  );
};

export default Slide169TwoPage;
