import React from "react";
// import { Link } from "react-router-dom";
import HomePage from "./Homepage";
import bgVideo from "./Homepage/video/bgvideo.mp4";
import { useLayoutEffect, useState } from "react";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [timeTick, setTimeTick] = useState(0);
  const [homeShow, setHomeShow] = useState(false);

  useLayoutEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setHomeShow(true)
    }, 6500);
  }, []);
  return (
    <>
      {homeShow ? (
        <HomePage />
      ) : (
        <div>
          <video
            style={{
              position: "absolute",
              right: "0",
              left: "0",
              bottom: "0",
              top: "0",
            }}
            autoPlay
            muted
          >
            <source src={bgVideo} type="video/mp4"></source>
          </video>
        </div>
      )}
    </>
  );
};
export default Home;
